<template>
  <button :disabled="disabled" :class="setClass()" @click="handleClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'HeaderButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'primary'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    setClass() {
      switch (this.type) {
        case 'default':
          return 'header__button_wrapper';
        case 'primary':
          return 'header__button_primary';
        default:
          return 'header__button_wrapper';
      }
    },
    handleClick() {
      this.$emit('click');
    },
  },
  components: {
  },
};
</script>
